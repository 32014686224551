import { takeLatest } from "redux-saga/effects";
import {
  ADD_GROUP,
  GET_ALL_GROUPS,
  GET_GROUP,
  UPDATE_GROUP,
} from "../redux/ducks/group";
import {
  ADD_GAME,
  FINISH_GAME,
  GET_GAME,
  GET_GAMES_FINAL,
  GET_GAMES_GROUP_STAGE,
  GET_GAMES_QUARTER_FINAL,
  GET_GAMES_SEMI_FINAL,
  MAKE_GAME_LIVE,
  UPDATE_GAME,
} from "../redux/ducks/game";
import { USER_LOGIN, GET_VALID_TOKEN } from "../redux/ducks/user";
import {
  handelGetAllGroups,
  handelAddGroup,
  handelGetGroup,
  handelUpdateGroup,
} from "./handlers/group";
import {
  handelAddGame,
  handelFinishGame,
  handelGetGame,
  handelGetGamesFinal,
  handelGetGamesGroupStage,
  handelGetGamesQuarterFinal,
  handelGetGamesSemiFinal,
  handelMakeGameLive,
  handelUpdateGame,
} from "./handlers/game";
import { handelLogin, handelGetValidToken } from "./handlers/user";
import { ADD_TEAM, GET_TEAM, UPDATE_TEAM } from "../redux/ducks/team";
import {
  handelAddTeam,
  handelGetTeam,
  handelUpdateTeam,
} from "./handlers/team";

export function* watcherSaga() {
  yield takeLatest(GET_ALL_GROUPS, handelGetAllGroups);
  yield takeLatest(GET_GROUP, handelGetGroup);
  yield takeLatest(ADD_GROUP, handelAddGroup);
  yield takeLatest(UPDATE_GROUP, handelUpdateGroup);

  yield takeLatest(GET_TEAM, handelGetTeam);
  yield takeLatest(ADD_TEAM, handelAddTeam);
  yield takeLatest(UPDATE_TEAM, handelUpdateTeam);

  yield takeLatest(GET_GAMES_GROUP_STAGE, handelGetGamesGroupStage);
  yield takeLatest(GET_GAMES_QUARTER_FINAL, handelGetGamesQuarterFinal);
  yield takeLatest(GET_GAMES_SEMI_FINAL, handelGetGamesSemiFinal);
  yield takeLatest(GET_GAMES_FINAL, handelGetGamesFinal);
  yield takeLatest(GET_GAME, handelGetGame);
  yield takeLatest(MAKE_GAME_LIVE, handelMakeGameLive);
  yield takeLatest(FINISH_GAME, handelFinishGame);
  yield takeLatest(ADD_GAME, handelAddGame);
  yield takeLatest(UPDATE_GAME, handelUpdateGame);

  yield takeLatest(USER_LOGIN, handelLogin);
  yield takeLatest(GET_VALID_TOKEN, handelGetValidToken);
}
