import { Provider } from "react-redux";
import store from "./redux/configStore";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./component/Home";
import { Route, Routes } from "react-router-dom";
import LogIn from "./component/Admin/login";
import Admin from "./component/Admin";
import EditGroup from "./component/Admin/editGroup";
import { PrivateRoute } from "./component/PrivateRoute";
import * as React from 'react';
import { blueGrey, grey } from '@mui/material/colors';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...blueGrey,
      ...(mode === 'dark' && {
        main: blueGrey[300],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: blueGrey[900],
        paper: blueGrey[900],
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
          primary: grey[900],
          secondary: grey[800],
        }
        : {
          primary: '#fff',
          secondary: grey[500],
        }),
    },
  },
});


function App() {

  const darkModeTheme = createTheme(getDesignTokens('dark'));

  return (
    <ThemeProvider theme={darkModeTheme}>
      <CssBaseline />
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/admin/group/:id"
            element={
              <PrivateRoute>
                <EditGroup />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin/group"
            element={
              <PrivateRoute>
                <EditGroup />
              </PrivateRoute>
            }
          />
          
         
          <Route
            exact
            path="/admin"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />

         <Route exact path="/admin/login" element={<LogIn />} />
        </Routes>
      </Provider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
