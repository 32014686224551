import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    popup: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor:"rgba(0, 0, 0, 0.5)",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },
    popupInner: {
        position: "relative",
        padding: "20px",
        width: "100%",
        maxWidth: "640px",
        
        textAlign: "center"
        //backgroundColor: "#546e7a",
        //borderRadius: "20px"
    }
    
  });
function Loading({trigger, setTrigger}) {
    const styles = useStyles();
    return (trigger)?(
        <div className={styles.popup}>
            <div className={styles.popupInner}>
                Loading....
            </div>
        </div>
    ):""
}

export default Loading
