import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SportsIcon from '@mui/icons-material/Sports';

const useStyles = makeStyles((theme) => ({
    card: {},
    cardContent: {},
    groupNameText: {
        borderBottom: "2mm ridge rgba(255, 42, 42, .6)"
    },
    timeText: {
        fontWeight: "bold!important"
    },
    game: {
        borderBottom: "1mm ridge rgba(255, 255, 255, .6)",
        marginBottom: "10px"
    },
    gameResultContainer: {
        display: "table",
        width: "100%"
    },
    gameActionContainer: {
        display: "table",
        width: "100%",
        paddingTop: "10px"
    },
    teamHome: {
        display: "table-cell",
        backgroundColor: "#4A7262",
        width: "45%",
        textAlign: "right",
        paddingRight: "5px"
    },
    x: {
        display: "table-cell",
        width: "10%",
        textAlign: "center"
    },
    teamAway: {
        display: "table-cell",
        backgroundColor: "#3B4D56",
        width: "45%",
        paddingLeft: "5px"
    },

    liveAction: {
        display: "table-cell",
        width: "33%",
        textAlign: "center",
        paddingRight: "5px"
    },

    finishAction: {
        display: "table-cell",
        width: "33%",
        textAlign: "center",
        paddingRight: "5px"
    },
    editAction: {
        display: "table-cell",
        width: "34%",
        textAlign: "center",
        paddingRight: "5px"
    },
    blinking: {
        WebkitAnimation: "1s $blink ease infinite",
        MozAnimation: "1s $blink ease infinite",
        "-ms-animation": "1s $blink ease infinite",
        "-o-animation": "1s $blink ease infinite",
        animation: "1s $blink ease infinite"
    },
    "@keyframes blink": {
        "from": {
            opacity: 0,
        },
        "to": {
            opacity: 0,
        },
        "50%": {
            opacity: 1
        }
    },
    
    "@-moz-keyframes blink": {
        "from": {
            opacity: 0,
        },
        "to": {
            opacity: 0,
        },
        "50%": {
            opacity: 1
        }
    },
    "@-webkit-keyframes blink": {
        "from": {
            opacity: 0,
        },
        "to": {
            opacity: 0,
        },
        "50%": {
            opacity: 1
        }
    },
    "@-ms-keyframes blink": {
        "from": {
            opacity: 0,
        },
        "to": {
            opacity: 0,
        },
        "50%": {
            opacity: 1
        }
    },
    "@-o-keyframes blink": {
        "from": {
            opacity: 0,
        },
        "to": {
            opacity: 0,
        },
        "50%": {
            opacity: 1
        }
    },
    table: {
    },

}));

function GameCard({ games, setLiveAction, editAction, finishGameAction }) {
    const styles = useStyles();
    const isNonMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    return (
        <Card variant="outlined" className={styles.card}>
            <CardContent className={styles.cardContent}>
                <Grid item xs={12}>
                    {isNonMobile && <Table className={styles.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell size="small" width={"5%"}></TableCell>
                                <TableCell align="left" width={"5%"}>Tid</TableCell>
                                <TableCell align="left" width={"12%"}>Hemma</TableCell>
                                <TableCell align="center">Resultat</TableCell>
                                <TableCell align="left" width={"12%"}>Borta</TableCell>
                                {(setLiveAction !== undefined && editAction !== undefined && finishGameAction !== undefined) &&
                                    <TableCell align="right" width={"12%"}>Actions</TableCell>
                                }

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {games.map((game) => (
                                <TableRow
                                    key={game.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" size="small">
                                        {game.live && <svg height={"30"} width={"30"} className={styles.blinking}>
                                            <circle cx={"15"} cy={"15"} r={"10"} fill={"red"}></circle>
                                        </svg>}
                                    </TableCell>
                                    <TableCell align="left">{game.time}</TableCell>
                                    <TableCell align="left">{game.homeTeam.name}</TableCell>
                                    <TableCell align="center">{game.homeTeamGoals + ' x ' + game.awayTeamGoals}</TableCell>
                                    <TableCell align="left" >{game.awayTeam.name}</TableCell>
                                    {(setLiveAction !== undefined && editAction !== undefined && finishGameAction !== undefined) &&
                                        <TableCell align="right">
                                            <IconButton disabled={game.live} onClick={() => setLiveAction(game)}>
                                                <LiveTvIcon />
                                            </IconButton>
                                            <IconButton disabled={!game.live} onClick={() => finishGameAction(game)}>
                                                <SportsIcon />
                                            </IconButton>
                                            <IconButton onClick={() => editAction(game)}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    }

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                    {!isNonMobile && games.map(game => (
                        <Grid container alignItems="center" className={styles.game} key={game.id}>
                            <Grid container alignItems="center"><Typography className={styles.timeText}>{game.time}</Typography>
                                {game.live && <svg height={"30"} width={"30"} className={styles.blinking}>
                                    <circle cx={"15"} cy={"15"} r={"10"} fill={"red"}></circle>
                                </svg>}
                            </Grid>
                            <div className={styles.gameResultContainer}>
                                <div className={styles.teamHome}>{game.homeTeam.name}</div>
                                <div className={styles.x}>X</div>
                                <div className={styles.teamAway}>{game.awayTeam.name}</div>
                            </div>
                            <div className={styles.gameResultContainer}>
                                <div className={styles.teamHome}>{game.homeTeamGoals}</div>
                                <div className={styles.x}>X</div>
                                <div className={styles.teamAway}>{game.awayTeamGoals}</div>
                            </div>
                            {(setLiveAction !== undefined &&  editAction !== undefined && finishGameAction !== undefined) &&
                                <div className={styles.gameActionContainer}>
                                    <div className={styles.liveAction}>
                                        <IconButton disabled={game.live} onClick={() => setLiveAction(game)}>
                                            <LiveTvIcon />
                                        </IconButton>
                                    </div>
                                    <div className={styles.finishAction}>
                                        <IconButton disabled={!game.live} onClick={() => finishGameAction(game)}>
                                            <SportsIcon />
                                        </IconButton>   
                                    </div>
                                    <div className={styles.editAction}>
                                        <IconButton onClick={() => editAction(game)}>
                                            <EditIcon />
                                        </IconButton>   
                                    </div>
                                </div>

                            }

                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>

    );
}

export default GameCard;
