import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addGroup,
  getGroup,
  setGroup,
  updateGroup,
} from "../../redux/ducks/group";
import { TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDebouncedCallback } from "use-debounce";

const useStyles = makeStyles({
  saveContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    textAlign: "right",
  },
});

function AddEditGroup({ validToken, id, setPopuupTrigger, reloadGroups }) {
  const dispatch = useDispatch();
  const { group, success } = useSelector((state) => state.group);
  const styles = useStyles();


  useEffect(() => {
    dispatch(getGroup(id));
    if (success) {
      setPopuupTrigger(false);
    }
  }, [dispatch, success, setPopuupTrigger, id]);


  const debouncedHandelChange = useDebouncedCallback((e) => {
    dispatch(setGroup({ ...group, [e.target.name]: e.target.value }));
  }, 500);
    
  const saveGroup = (event) => {
    event.preventDefault();
    if (group.id) {
      dispatch(updateGroup(group, validToken));
    } else {
      dispatch(addGroup(group, validToken));
    }
    reloadGroups();
  };

  return (
    <>
      {group && (
        <div>
          <TextField
            fullWidth
            required
            label="Group Name"
            defaultValue={group.name}
            name="name"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />

          <div className={styles.saveContainer}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={saveGroup}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default AddEditGroup;
