import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { finishGame } from "../../redux/ducks/game";

const useStyles = makeStyles({
  saveContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    textAlign: "right",
  },
});

function FinishGame({ validToken, game, setPopuupTrigger, reloadGames }) {
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.game);
  const styles = useStyles();

  useEffect(() => {
    if (success) {
      setPopuupTrigger(false);
    }
  }, [dispatch, success, setPopuupTrigger]);

  const handelFinishGame = (event) => {
    event.preventDefault();
    dispatch(finishGame(game, validToken));
    reloadGames();
  };

  return (
    <>
      {game && (
        <div>
          <Typography>Do you realy want to finish this game?</Typography>
          <div className={styles.saveContainer}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={handelFinishGame}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default FinishGame;
