import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

const useStyles = makeStyles({
  team: {
    padding: "15px 10px 10px",
    marginTop: "20px",
    border: "solid white",
    borderRadius: "10px"
  },
  teamHeader:{
    position: "absolute",
    marginTop: "-30px",
    marginLeft: "10px",
    color: "#263238",
    background: "white",
    paddingRight: "5px",
    paddingLeft: "5px",
    borderRadius: "5px"
  }
});

function Team({team, index, changeHandle}) {
 
  const classes = useStyles();
  return (
    <div className={classes.team}>
          <div className={classes.teamHeader}>Team ({index+1})</div>
          <TextField required label="Team Name" name={index+".name"} value={team.name} onChange={changeHandle} />
          <TextField label="M" name={index+".matchs"} type="number" value={team.matchs} onChange={changeHandle} />
          <TextField label="V" name={index+".wins"} type="number" value={team.wins} onChange={changeHandle} />
          <TextField label="O" name={index+".draws"} type="number" value={team.draws} onChange={changeHandle} />
          <TextField label="F" name={index+".losses"}type="number" value={team.losses} onChange={changeHandle}  />
          <TextField label="GM" name={index+".goalsScored"} type="number" value={team.goalsScored} onChange={changeHandle} />
          <TextField label="IM" name={index+".goalsAgains"} type="number" value={team.goalsAgains} onChange={changeHandle} />
          <TextField label="P" name={index+".point"} type="number"  value={team.point} onChange={changeHandle} />
      </div>
  );
}
export default Team;
