import { call, put } from "redux-saga/effects";
import {
  setGame,
  setGamesFinal,
  setGamesGroupStage,
  setGamesQuarterFinal,
  setGamesSimeFinal,
} from "../../redux/ducks/game";
import {
  requestAddGame,
  requestFinishGame,
  requestGetGame,
  requestGetGamesFinal,
  requestGetGamesGroupStage,
  requestGetGamesQuarterFinal,
  requestGetGamesSemiFinal,
  requestMakeGameLive,
  requestUpdateGame,
} from "../requests/game";
import { toast } from "react-toastify";
import { setActionSuccess } from "../../redux/ducks/group";

export function* handelGetGamesGroupStage() {
  try {
    const response = yield call(requestGetGamesGroupStage);
    const { data } = response;
    yield put(setGamesGroupStage(data));
  } catch (err) {
    notifyError(err.response.data);
  }
}

export function* handelGetGamesQuarterFinal() {
  try {
    const response = yield call(requestGetGamesQuarterFinal);
    const { data } = response;
    yield put(setGamesQuarterFinal(data));
  } catch (err) {
    notifyError(err.response.data);
  }
}

export function* handelGetGamesSemiFinal() {
  try {
    const response = yield call(requestGetGamesSemiFinal);
    const { data } = response;
    yield put(setGamesSimeFinal(data));
  } catch (err) {
    notifyError(err.response.data);
  }
}

export function* handelGetGamesFinal() {
  try {
    const response = yield call(requestGetGamesFinal);
    const { data } = response;
    yield put(setGamesFinal(data));
  } catch (err) {
    notifyError(err.response.data);
  }
}

export function* handelGetGame({ id, gameLevel, token }) {
  try {
    if (!id) {
      yield put(
        setGame({
          time: "",
          gameLevel: gameLevel,
          homeTeam: {},
          awayTeam: {},
          homeTeamGoals: 0,
          awayTeamGoals: 0,
        })
      );
    } else {
      const response = yield call(requestGetGame, { id, token });
      const { data } = response;
      yield put(setGame(data));
    }
  } catch (err) {
    notifyError(err.response.data.detail);
  }
}

export function* handelMakeGameLive({ game, token }) {
  try {
    const response = yield call(requestMakeGameLive, {gameId: game.id, token});
    if (response.status === 200) {
        yield put(setActionSuccess(true));
      } else {
        yield put(setActionSuccess(false));
        console.log(response);
        notifyError(response.data);
      }
  } catch (err) {
    notifyError(err.response.data.detail);
  }
}

export function* handelFinishGame({ game, token }) {
  try {
    const response = yield call(requestFinishGame, {gameId: game.id, token});
    if (response.status === 200) {
        yield put(setActionSuccess(true));
      } else {
        yield put(setActionSuccess(false));
        console.log(response);
        notifyError(response.data);
      }
  } catch (err) {
    notifyError(err.response.data.detail);
  }
}

export function* handelAddGame({ game, token }) {
  try {
    const response = yield call(requestAddGame, {
      game: {
        time: game.time,
        gameLevel: game.gameLevel,
        homeTeamId: game.homeTeam.id,
        awayTeamId: game.awayTeam.id,
      },
      token,
    });
    if (response.status === 201) {
      const { data: game } = response;
      yield put(setGame(game));
      yield put(setActionSuccess(true));
    } else {
      yield put(setActionSuccess(false));
      console.log(response);
      notifyError(response.data);
    }
  } catch (err) {
    console.log(err.response);
    yield put(setActionSuccess(false));
    notifyError(err.response.data.detail);
  }
}

export function* handelUpdateGame({ game, token }) {
  try {
    const response = yield call(requestUpdateGame, {
      id: game.id,
      game: {
        id: game.id,
        time: game.time,
        homeTeamId: game.homeTeam.id,
        awayTeamId: game.awayTeam.id,
        homeTeamGoals: game.homeTeamGoals,
        awayTeamGoals: game.awayTeamGoals,
      },
      token,
    });
    if (response.status === 200) {
      const { data: game } = response;
      yield put(setGame(game));
      yield put(setActionSuccess(true));
    } else {
      yield put(setActionSuccess(false));
    }
  } catch (err) {
    console.log(err.response);
    yield put(setActionSuccess(false));
    notifyError(err.response.data.detail);
  }
}

const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
