import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useDebouncedCallback } from "use-debounce";
import { addGame, getGame, setGame, updateGame } from "../../redux/ducks/game";
import {
  TextField,
  Button,
  Autocomplete,
  Grid,
} from "@mui/material";

const useStyles = makeStyles({
  saveContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    textAlign: "right",
  },
});

function AddEditGame({
  validToken,
  gameLevel,
  id,
  setPopuupTrigger,
  reloadGames,
}) {
  const dispatch = useDispatch();
  const { game, success } = useSelector((state) => state.game);
  const { groups } = useSelector((state) => state.group);
  const styles = useStyles();

  useEffect(() => {
    // dispatch(getAllGroups());
    dispatch(getGame(id, gameLevel, validToken));

    if (success) {
      setPopuupTrigger(false);
    }
  }, [dispatch, success, setPopuupTrigger, id, gameLevel, validToken]);

  const debouncedHandelChange = useDebouncedCallback((e) => {
    dispatch(setGame({ ...game, [e.target.name]: e.target.value }));
  }, 500);

  const saveGame = (event) => {
    event.preventDefault();
    if (game.id) {
      dispatch(updateGame(game, validToken));
    } else {
      dispatch(addGame(game, validToken));
    }
    reloadGames();
  };

  const debouncedTeamSelect = useDebouncedCallback((key, value) => {
    dispatch(setGame({ ...game, [key]: value }));
  }, 500);

  const getTeams = () => {
    const teams = [];

    groups.forEach((group) => {
      group.teams.forEach((team) => {
        teams.push(team);
      });
    });
    return teams;
  };

  return (
    <>
      {game && groups && (
        <div>
          <TextField
            fullWidth
            required
            label="Time"
            defaultValue={game.time}
            name="time"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />
          <Grid container direction={"row"}>
            <Grid item xs={12} sm={6} sx={{pr:1}}>
              <Autocomplete
                disablePortal
                options={getTeams()}
                sx={{ mr: 1, mt: 3, mb: 3 }}
                getOptionLabel={(option) => option.name === undefined? '' : option.name}
                value={game.homeTeam}
                isOptionEqualToValue={(option, value) =>
                  value?.id === undefined || value.id === "" || option.id === value.id
                }
                onChange={(event, newValue) => {
                  debouncedTeamSelect("homeTeam", newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Home Team" />
                )}
              />
              {game.id && <TextField
                label="Home Team Goals"
                sx={{ mr: 1}}
                type="number"
                defaultValue={game.homeTeamGoals}
                name="homeTeamGoals"
                fullWidth
                variant="standard"
                onChange={(e) => debouncedHandelChange(e)}
              />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={getTeams()}
                sx={{ mr: 1, mt: 3 , mb: 3}}
                getOptionLabel={(option) => option.name === undefined? '' : option.name}
                value={game.awayTeam}
                isOptionEqualToValue={(option, value) =>
                    value?.id === undefined || value.id === "" || option.id === value.id
                }
                onChange={(event, newValue) => {
                  debouncedTeamSelect("awayTeam", newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Away Team" />
                )}
              />
              {game.id && <TextField
                label="Away Team Goals"
                sx={{ mr: 1}}
                type="number"
                defaultValue={game.awayTeamGoals}
                name="awayTeamGoals"
                fullWidth
                variant="standard"
                onChange={(e) => debouncedHandelChange(e)}
              />}
            </Grid>
          </Grid>

          <div className={styles.saveContainer}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={saveGame}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default AddEditGame;
