import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getAllGroups,
  setActionSuccess,
  setGroup,
} from "../../redux/ducks/group";
import GroupCard from "../GroupCard";
import uuid from "react-uuid";
import { makeStyles } from "@mui/styles";
import { Box, Tabs, Tab, Grid } from "@mui/material";

import { Button } from "@mui/material";
import {
  getGamesFinal,
  getGamesGroupStage,
  getGamesQuarterFinal,
  getGamesSemiFinal,
  makeGameLive,
  setGame,
} from "../../redux/ducks/game";
import useMediaQuery from "@mui/material/useMediaQuery";
import GameCard from "../GameCard";
import { getValidToken } from "../../redux/ducks/user";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Popup from "../Popup";
import AddEditGroup from "./AddEditGroup";
import { useDebouncedCallback } from "use-debounce";
import Loading from "../Loading";
import { setTeam } from "../../redux/ducks/team";
import AddEditTeam from "./AddEditTeam";
import AddEditGame from "./AddEditGame";
import FinishGame from "./FinishGame";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function Admin() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [popupTrigger, setPopupTrigger] = useState(false);
  const [popupChildren, setPopupChildren] = useState(<></>);
  const [loading, setLoading] = useState(false);
  const isNonMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllGroups(true));
    dispatch(getGamesGroupStage());
    dispatch(getGamesQuarterFinal());
    dispatch(getGamesSemiFinal());
    dispatch(getGamesFinal());
    dispatch(getValidToken());
  }, [dispatch]);

  const { validToken } = useSelector((state) => state.user);
  const { groups } = useSelector((state) => state.group);
  const { gamesGroupStage, gamesQuarterFinal, gamesSimeFinal, gamesFinal } =
    useSelector((state) => state.game);

  const classes = useStyles();

  const viewAction = (group) => {
    dispatch(setActionSuccess(false));
    navigate("/admin/group/" + group._id, { replace: true });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const reloadGroups = useDebouncedCallback(() => {
    dispatch(getAllGroups(true));
  }, 1000);

  const reloadGames = useDebouncedCallback(() => {
    dispatch(getGamesGroupStage());
    dispatch(getGamesQuarterFinal());
    dispatch(getGamesSemiFinal());
    dispatch(getGamesFinal());
  }, 1000);

  // const addGroupAction = () => {
  //   dispatch(setActionSuccess(false))
  //   setPopupChildren(<AddEditGroup validToken={validToken} setPopuupTrigger={setPopupTrigger} reloadGroups={reloadGroups} />)
  //   setPopupTrigger(true)
  //   //navigate("/admin/group")
  // };

  const addGroupAction = () => {
    setLoading(true);
    debounceAction(() => {
      dispatch(setActionSuccess(false));
      dispatch(setGroup(undefined));
      setPopupChildren(
        <AddEditGroup
          validToken={validToken}
          setPopuupTrigger={setPopupTrigger}
          reloadGroups={reloadGroups}
        />
      );
      setLoading(false);
      setPopupTrigger(true);
    });
  };

  const addTeamAction = (group) => {
    setLoading(true);
    debounceAction(() => {
      dispatch(setActionSuccess(false));
      dispatch(setTeam(undefined));
      setPopupChildren(
        <AddEditTeam
          validToken={validToken}
          setPopuupTrigger={setPopupTrigger}
          reloadGroups={reloadGroups}
          teamGroupId={group.id}
        />
      );
      setLoading(false);
      setPopupTrigger(true);
    });
  };

  const addGameAction = (gameLevel) =>{
    setLoading(true);
    debounceAction(() => {
      dispatch(setActionSuccess(false));
      dispatch(setGame(undefined));
      setPopupChildren(
        <AddEditGame
          validToken={validToken}
          setPopuupTrigger={setPopupTrigger}
          reloadGames={reloadGames}
          gameLevel={gameLevel}
        />
      );
      setLoading(false);
      setPopupTrigger(true);
    });
  }

  const editGroupAction = (group) => {
    setLoading(true);
    debounceAction(() => {
      dispatch(setActionSuccess(false));
      dispatch(setGroup(undefined));
      setPopupChildren(
        <AddEditGroup
          validToken={validToken}
          id={group.id}
          setPopuupTrigger={setPopupTrigger}
          reloadGroups={reloadGroups}
        />
      );
      setLoading(false);
      setPopupTrigger(true);
    });
  };

  const editTeamAction = (team) => {
    setLoading(true);
    debounceAction(() => {
      dispatch(setActionSuccess(false));
      dispatch(setTeam(undefined));
      setPopupChildren(
        <AddEditTeam
          validToken={validToken}
          setPopuupTrigger={setPopupTrigger}
          reloadGroups={reloadGroups}
          id={team.id}
        />
      );
      setLoading(false);
      setPopupTrigger(true);
    });
  };

  const editGameAction = (game) => {
    setLoading(true);
    debounceAction(() => {
      dispatch(setActionSuccess(false));
      dispatch(setGame(undefined));
      setPopupChildren(
        <AddEditGame
          validToken={validToken}
          setPopuupTrigger={setPopupTrigger}
          reloadGames={reloadGames}
          id={game.id}
        />
      );
      setLoading(false);
      setPopupTrigger(true);
    });
  };

  const finishGameAction = (game) => {
    setLoading(true);
    debounceAction(() => {
      dispatch(setActionSuccess(false));
      setPopupChildren(
        <FinishGame
          validToken={validToken}
          setPopuupTrigger={setPopupTrigger}
          reloadGames={reloadGames}
          reloadGroups={reloadGroups}
          game={game}
        />
      );
      setLoading(false);
      setPopupTrigger(true);
    });
  };

  const setGameLiveAction = (game) => {
    dispatch(makeGameLive(game, validToken));
    reloadGames();
  };

  const debounceAction = useDebouncedCallback((callback) => {
    callback();
  }, 100);

  // const editGroupAction = (group)=>{

  //   console.log("EDIT GROUP ACTION", group)
  //   dispatch(setActionSuccess(false))
  //   dispatch(setGroup(undefined))
  //   setPopupChildren(<AddEditGroup validToken={validToken} id={group.id} setPopuupTrigger={setPopupTrigger} reloadGroups={reloadGroups} />)
  //   setPopupTrigger(true)
  // };
 

  let index = 0;
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>       
        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable">
         <Tab label="Grupper" />
          <Tab label="Gruppspel" />
          <Tab label="Kvartsfinal" />
          <Tab label="Semifinal" />
          <Tab label="Final" />
        </Tabs>
      </Box>

      {/*GROUPS*/}
      <TabPanel value={selectedTab} index={index++}>
        <Grid style={{ marginBottom: "20px" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddBoxIcon />}
            onClick={addGroupAction}
          >
            Add
          </Button>
        </Grid>
        {groups && (
          <Grid
            container
            spacing={4}
            className={
              isNonMobile ? classes.gridContainer : classes.gridContainerSM
            }
            justify="center"
          >
            {groups.map((group) => (
              <Grid item key={uuid()} xs={12} sm={4} md={6}>
                {/* <Groups key={uuid()} group={group} /> */}
                <GroupCard
                  group={group}
                  action={() => viewAction(group)}
                  editGroupAction={() => editGroupAction(group)}
                  editTeamAction={editTeamAction}
                  addTeamAction={addTeamAction}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </TabPanel>

      {/*GAME STAGE*/}
      <TabPanel value={selectedTab} index={index++}>
        <Grid style={{ marginBottom: "20px" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddBoxIcon />}
            onClick={()=>addGameAction("GroupStage")}
          >
            Add Gruppspell Game
          </Button>
        </Grid>
        {gamesGroupStage && (
          <Grid
            container
            spacing={4}
            className={
              isNonMobile ? classes.gridContainer : classes.gridContainerSM
            }
            justify="center"
          >
            <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
              <GameCard
                games={gamesGroupStage}
                setLiveAction={setGameLiveAction}
                editAction={editGameAction}
                finishGameAction={finishGameAction}
              />
            </Grid>
          </Grid>
        )}
      </TabPanel>

      {/*QUARTER FINAL*/}
      <TabPanel value={selectedTab} index={index++}>
        <Grid style={{ marginBottom: "20px" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddBoxIcon />}
            onClick={()=>addGameAction("QuarterFinal")}
          >
            Add Kvartsfinal Game
          </Button>
        </Grid>
        {gamesQuarterFinal && (
          <Grid
            container
            spacing={4}
            className={
              isNonMobile ? classes.gridContainer : classes.gridContainerSM
            }
            justify="center"
          >
            <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
              <GameCard
                games={gamesQuarterFinal}
                setLiveAction={setGameLiveAction}
                editAction={editGameAction}
                finishGameAction={finishGameAction}
              />
            </Grid>
          </Grid>
        )}
      </TabPanel>

      {/*SEMI FINAL*/}
      <TabPanel value={selectedTab} index={index++}>
        <Grid style={{ marginBottom: "20px" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddBoxIcon />}
            onClick={()=>addGameAction("SemiFinal")}
          >
            Add Simifinal Game
          </Button>
        </Grid>
        {gamesSimeFinal && (
          <Grid
            container
            spacing={4}
            className={
              isNonMobile ? classes.gridContainer : classes.gridContainerSM
            }
            justify="center"
          >
            <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
              <GameCard
                games={gamesSimeFinal}
                setLiveAction={setGameLiveAction}
                editAction={editGameAction}
                finishGameAction={finishGameAction}
              />
            </Grid>
          </Grid>
        )}
      </TabPanel>

      {/*FINAL*/}
      <TabPanel value={selectedTab} index={index++}>
        <Grid style={{ marginBottom: "20px" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddBoxIcon />}
            onClick={()=>addGameAction("Final")}
          >
            Add Final Game
          </Button>
        </Grid>
        {gamesFinal && (
          <Grid
            container
            spacing={4}
            className={
              isNonMobile ? classes.gridContainer : classes.gridContainerSM
            }
            justify="center"
          >
            <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
              <GameCard
                games={gamesFinal}
                setLiveAction={setGameLiveAction}
                editAction={editGameAction}
                finishGameAction={finishGameAction}
              />
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <Popup trigger={popupTrigger} setTrigger={setPopupTrigger}>
        {popupChildren}
      </Popup>
      <Loading trigger={loading} setTrigger={setLoading} />
    </>
  );
}
export default Admin;
