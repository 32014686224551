import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  CardActions,
  IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
const useStyles = makeStyles((theme) => ({
  group: {},
  card: {},
  table: {},
  groupNameText: {
    borderBottom: "2mm ridge rgba(255, 42, 42, .6)"
  },
  teamNameText: {
    fontWeight: "bold!important",
  },
  addTeamButton: {
    marginLeft: "15px",
    marginBottom: "15px",
  }

}));

function NonMobile({ group, action, editGroupAction, editTeamAction, addTeamAction }) {
  const styles = useStyles();
  return (
    <Card variant="outlined" className={styles.card}>
      <React.Fragment>
        <CardContent className={styles.card}>
          <Typography variant="h5" component="div" className={styles.groupNameText}>
            {group.name} {editGroupAction && <IconButton color="secondary" onClick={() => editGroupAction(group)}><EditIcon /></IconButton>}
          </Typography>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">M</TableCell>
                <TableCell align="right">V</TableCell>
                <TableCell align="right">O</TableCell>
                <TableCell align="right">F</TableCell>
                <TableCell align="right">GM</TableCell>
                <TableCell align="right">IM</TableCell>
                <TableCell align="right">+/-</TableCell>
                <TableCell align="right">P</TableCell>
                {editTeamAction && <TableCell align="right">Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {group.teams.map((team) => (
                <TableRow
                  key={team.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" className={styles.teamNameText}>{team.name}</TableCell>
                  <TableCell align="right">{team.matches}</TableCell>
                  <TableCell align="right">{team.wins}</TableCell>
                  <TableCell align="right">{team.draws}</TableCell>
                  <TableCell align="right">{team.losses}</TableCell>
                  <TableCell align="right">{team.goalsScored}</TableCell>
                  <TableCell align="right">{team.goalsAgainst}</TableCell>
                  <TableCell align="right">{team.goalsScored - team.goalsAgainst}</TableCell>
                  <TableCell align="right">{team.points}</TableCell>
                  {editTeamAction && <TableCell align="right"><IconButton color="secondary" onClick={() => editTeamAction(team)}><EditIcon /></IconButton></TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        {addTeamAction &&
          <CardActions className={styles.addTeamButton}>
            <Button variant="contained" color="secondary" startIcon={<AddBoxIcon />} onClick={() => addTeamAction(group)}>Add team</Button>
          </CardActions>}
      </React.Fragment>
    </Card>

  );
}

export default NonMobile;
