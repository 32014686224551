export const GET_TEAM = "getTeam";
export const ADD_TEAM = "addTeam";
export const UPDATE_TEAM = "updateTeam";
const SET_TEAM = "setTeam";
const SET_ACTION_SUCCESS = "setActionSuccess";


export const setTeam = (team) => ({
    type: SET_TEAM,
    team: team,
  });

export const getTeam = (id, groupId, token) => ({
    type: GET_TEAM,
    id : id,
    groupId: groupId,
    token: token
  });

  export const addTeam = (team, token) => ({
    type: ADD_TEAM,
    team: team,
    token: token
  });
  
  export const updateTeam = (team, token) => ({
    type: UPDATE_TEAM,
    team: team,
    token: token
  });

  export const setActionSuccess = (success) => ({
    type: SET_ACTION_SUCCESS,
    success: success,
  });

  const initialState = {
    team: undefined,
    success: false
  };

  const actionHandler = (state = initialState, action) => {
    switch (action.type) {
      case SET_TEAM:
        const { team } = action;
        return { ...state, team: team };
      case SET_ACTION_SUCCESS:
          const { success } = action;
          return { ...state,  success: success };
      default:
        return state;
    }
  };
  
  export default actionHandler;
  