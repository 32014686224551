export const USER_LOGIN = "userLogin";
export const GET_VALID_TOKEN = "getValidToken";
const SET_TOKEN = "setToken";
const SET_AUTHENTICATED = "setAuthenticated";
const SET_VALID_TOKEN = "setValidToken";

export const userLogin = (username, password) => ({
  type: USER_LOGIN,
  username: username,
  password: password,
});

export const getValidToken = () => ({
  type: GET_VALID_TOKEN,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  token: token,
});

export const setValidToken = (validToken) => ({
  type: SET_VALID_TOKEN,
  validToken: validToken,
});

export const setAuthenticated = (isAuthenticated) => ({
  type: SET_AUTHENTICATED,
  authenticated: isAuthenticated
});

const initialState = {
  token: localStorage.getItem("token"),
  validToken: undefined,
  authenticated: undefined
};

const actionHandler = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      const { token } = action;
      token
        ? localStorage.setItem("token", token)
        : localStorage.removeItem("token");
      return { ...state, token: token };
    case SET_VALID_TOKEN:
      const { validToken } = action;
      return { ...state, validToken: validToken };
    case SET_AUTHENTICATED:
      const { authenticated } = action;
      return { ...state, authenticated: authenticated };
    default:
      return state;
  }
};

export default actionHandler;
