import Header from "../Header";
import TabSelector from "../TabSelector";

function Home() {
  return (
    <>
      <Header />
      <TabSelector />
    </>
  );
}

export default Home;
