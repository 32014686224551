import { Box, Tabs, Tab, Grid } from "@mui/material";
import { useState } from "react";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllGroups } from "../../redux/ducks/group";
import { getGamesFinal, getGamesGroupStage, getGamesQuarterFinal, getGamesSemiFinal } from "../../redux/ducks/game";
import GroupCard from "../GroupCard";
import { makeStyles } from "@mui/styles";
import GameCard from "../GameCard";
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "25px",
    paddingRight: "25px",   
  },
  gridContainerSM: {
    paddingLeft: "7px",
    paddingRight: "7px",   
  },
  groupCardGrid: {
    //paddingRight: "25px",
  },
  gameCardGrid: {
    //paddingRight: "25px",
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function TabSelector() {
  const isNonMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllGroups(false));
    dispatch(getGamesGroupStage());
    dispatch(getGamesQuarterFinal());
    dispatch(getGamesSemiFinal());
    dispatch(getGamesFinal());
  },  [dispatch]);
  
  const {groups} = useSelector((state) => state.group);
  const {gamesGroupStage, gamesQuarterFinal, gamesSimeFinal, gamesFinal} = useSelector((state) => state.game);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const classes = useStyles();
  let index = 0;
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
          <Tab label="Grupper" />
          <Tab label="Gruppspel" />
          <Tab label="Kvartsfinal" />
          <Tab label="Semifinal" />
          <Tab label="Final" />
          {/* {groups &&
            groups.map((group) => <Tab key={uuid()} label={group.name} />)} */}
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={index++}>
        {groups && (
          <Grid container spacing={4} className={isNonMobile? classes.gridContainer: classes.gridContainerSM} justify="center">
            {groups.map((group, i) => (
              <Grid item key={uuid()} xs={12} className={classes.groupCardGrid}>
                {/* <Groups key={uuid()} group={group} /> */}
                <GroupCard group={group} /> {/*  action={() => viewAction(i + 1)}*/}
              </Grid>
            ))}
          </Grid>
        )}
        {/* <Groups></Groups> */}
      </TabPanel>
      <TabPanel value={selectedTab} index={index++}>
        {gamesGroupStage && (
            <Grid container spacing={4} className={isNonMobile? classes.gridContainer: classes.gridContainerSM} justify="center">
              <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
                <GameCard games={gamesGroupStage}/>
              </Grid>
            </Grid>
          )}
      </TabPanel>
      <TabPanel value={selectedTab} index={index++}>
        {gamesQuarterFinal && (
            <Grid container spacing={4} className={isNonMobile? classes.gridContainer: classes.gridContainerSM} justify="center">
              <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
                <GameCard games={gamesQuarterFinal}/>
              </Grid>
            </Grid>
          )}
      </TabPanel>
      <TabPanel value={selectedTab} index={index++}>
        {gamesSimeFinal && (
            <Grid container spacing={4} className={isNonMobile? classes.gridContainer: classes.gridContainerSM} justify="center">
              <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
                <GameCard games={gamesSimeFinal}/>
              </Grid>
            </Grid>
          )}
      </TabPanel>
      <TabPanel value={selectedTab} index={index++}>
        {gamesFinal && (
            <Grid container spacing={4} className={isNonMobile? classes.gridContainer: classes.gridContainerSM} justify="center">
              <Grid item key={uuid()} xs={12} className={classes.gameCardGrid}>
                <GameCard games={gamesFinal}/>
              </Grid>
            </Grid>
          )}
      </TabPanel>
      {/* {groups &&
        groups.map((group) => (
          <TabPanel key={uuid()} value={selectedTab} index={index++}>
            {group.name}
          </TabPanel>
        ))} */}
    </>
  );
}

export default TabSelector;
