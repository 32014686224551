export const GET_GROUP = "getGroup";
export const ADD_GROUP = "addGroup";
export const GET_ALL_GROUPS = "getAllGroups";
export const UPDATE_GROUP = "updateGroup";
const SET_GROUP = "setGroup";
const SET_ALL_GROUPS = "setAllGroups";
const SET_ACTION_SUCCESS = "setActionSuccess";

export const getGroup = (id) => ({
  type: GET_GROUP,
  id : id
});

export const addGroup = (group, token) => ({
  type: ADD_GROUP,
  group: group,
  token: token
});

export const updateGroup = (group, token) => ({
  type: UPDATE_GROUP,
  group: group,
  token: token
});

export const getAllGroups = (includeHidden) => ({
  type: GET_ALL_GROUPS,
  includeHidden: includeHidden
});

export const setGroup = (group) => ({
  type: SET_GROUP,
  group: group,
});

export const setActionSuccess = (success) => ({
  type: SET_ACTION_SUCCESS,
  success: success,
});
export const setAllGroups = (groups) => ({
  type: SET_ALL_GROUPS,
  groups: groups,
});

const initialState = {
  group: undefined,
  groups: undefined,
  success: false
};

const actionHandler = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUP:
      const { group } = action;
      return { ...state, group: group };
    case SET_ALL_GROUPS:
        const { groups } = action;
        return { ...state, groups: groups };
    case SET_ACTION_SUCCESS:
        const { success } = action;
        return { ...state,  success: success };
    default:
      return state;
  }
};

export default actionHandler;
