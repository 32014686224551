import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useDebouncedCallback } from "use-debounce";
import { addTeam, getTeam, setTeam, updateTeam } from "../../redux/ducks/team";
import { TextField, Button } from "@mui/material";

const useStyles = makeStyles({
  saveContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    textAlign: "right",
  },
});

function AddEditTeam({
  validToken,
  id,
  setPopuupTrigger,
  reloadGroups,
  teamGroupId,
}) {
  const dispatch = useDispatch();
  const { team, success } = useSelector((state) => state.team);
  const styles = useStyles();

  useEffect(() => {
    dispatch(getTeam(id, teamGroupId, validToken));
    if (success) {
      setPopuupTrigger(false);
    }
  }, [dispatch, success, setPopuupTrigger, id, teamGroupId, validToken]);

  const debouncedHandelChange = useDebouncedCallback((e) => {
    dispatch(setTeam({ ...team, [e.target.name]: e.target.value }));
  }, 500);

  const saveTeam = (event) => {
    event.preventDefault();
    if (team.id) {
      dispatch(updateTeam(team, validToken));
    } else {
      dispatch(addTeam(team, validToken));
    }
    reloadGroups();
  };

  return (
    <>
      {team && (
        <>
          <TextField
            fullWidth
            required
            label="Team Name"
            defaultValue={team.name}
            name="name"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />

          <TextField
            label="Matches"
            sx={{ mr: 1, mt: 1 }}
            type="number"
            defaultValue={team.matches}
            name="matches"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />

          <TextField
            label="Wins"
            sx={{ mr: 1, mt: 1 }}
            type="number"
            defaultValue={team.wins}
            name="wins"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />

          <TextField
            label="Draws"
            sx={{ mr: 1, mt: 1 }}
            type="number"
            defaultValue={team.draws}
            name="draws"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />

          <TextField
            label="Losses"
            sx={{ mr: 1, mt: 1 }}
            type="number"
            defaultValue={team.losses}
            name="losses"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />

          <TextField
            label="Goals Scored"
            sx={{ mr: 1, mt: 1 }}
            type="number"
            defaultValue={team.goalsScored}
            name="goalsScored"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />
          <TextField
            label="Goals Against"
            sx={{ mr: 1, mt: 1 }}
            type="number"
            defaultValue={team.goalsAgainst}
            name="goalsAgainst"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />
          <TextField
            label="Points"
            sx={{ mr: 1, mt: 1 }}
            type="number"
            defaultValue={team.points}
            name="points"
            variant="standard"
            onChange={(e) => debouncedHandelChange(e)}
          />
          <div className={styles.saveContainer}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={saveTeam}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default AddEditTeam;
