import {
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import NonMobile from "./NonMobile";
import Mobile from "./Mobile";

const useStyles = makeStyles((theme) => ({
  group: {},
  card: {},
  table: {},
  groupNameText: {
    borderBottom: "2mm ridge rgba(255, 42, 42, .6)"
  },
  teamNameText: {
    fontWeight: "bold!important"
  }
  
}));

function GroupCard({ group, action, editGroupAction, editTeamAction, addTeamAction }) {
  const isNonMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const styles = useStyles();
  return (
    <Box className={styles.group}>
     {isNonMobile && <NonMobile group={group} action={action} editGroupAction={editGroupAction} editTeamAction={editTeamAction} addTeamAction={addTeamAction}/>}
     {!isNonMobile && <Mobile group={group} action={action} editGroupAction={editGroupAction} editTeamAction={editTeamAction} addTeamAction={addTeamAction}/>}
    </Box>
  );
}

export default GroupCard;
