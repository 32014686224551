import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TopImage from '../../assets/04.jpg';

const useStyles = makeStyles((theme) => ({
    hero: {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${TopImage}')`,
        height: "500px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "4rem",
        [theme.breakpoints.down("sm")]: {
            height: 300,
            fontSize: "3em"
        }
    },
    
}));

function Header() {
    const styles = useStyles();

    return (
        <Box className={styles.hero}>
            <Box>
                <Typography fontFamily={"fantasy"} fontSize={"3rem"}>Futsal 2023</Typography>
            
                <Typography fontFamily={"fantasy"} fontSize={"2rem"}>Dalarna futsal-turnering</Typography>
            </Box>
            
        </Box>
        
    )
}
export default Header;