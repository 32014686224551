import axios from "axios";
import getEnv from "../../env";

const baseUrl = getEnv().FUTSAL_SERVICE_URL;

export function requestLogin(data) {
  return axios.request({
    method: "post",
    url: baseUrl + '/user/login',
    data: data,
  });
}
