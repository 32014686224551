import {
  Card,
  Grid,
  CardContent,
  Typography,
  CardActions,
  Button,
  IconButton
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { makeStyles } from "@mui/styles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  teamName: {
    marginBottom: "5px",
    marginTop: "15px"
  },
  card: {
    padding: "10px"
  },
  team: {
    borderBottom: "1mm ridge rgba(255, 255, 255, .6)",

  },
  table: {},
  groupNameText: {
    borderBottom: "2mm ridge rgba(255, 42, 42, .6)"
  },
  teamNameText: {
    fontWeight: "bold!important"
  }

}));

function Mobile({ group, action, editGroupAction, editTeamAction, addTeamAction }) {

  const styles = useStyles();
  return (
    <Card variant="outlined" className={styles.card}>
      <Typography variant="h5" component="div" className={styles.groupNameText}>
        {group.name} {editGroupAction && <IconButton color="secondary" onClick={() => editGroupAction(group)}><EditIcon /></IconButton>}
      </Typography>
      <React.Fragment>
        <CardContent className={styles.card}>
          {group.teams.map((team) => (
            <Grid key={team.id} className={styles.team}>
              <Grid className={[styles.teamName, styles.teamNameText].join(" ")}>
                {team.name + " (P: " + team.points + ")"}
                {editTeamAction && <IconButton color="secondary" onClick={() => editTeamAction(team)}><EditIcon /></IconButton>}
              </Grid>

              <Grid id="top-row" container >
                <Grid item xs={2}>M</Grid>
                <Grid item xs={1}>V</Grid>
                <Grid item xs={1}>O</Grid>
                <Grid item xs={2}>F</Grid>
                <Grid item xs={2}>GM</Grid>
                <Grid item xs={2}>IM</Grid>
                <Grid item xs={1}>+/-</Grid>
              </Grid>
              <Grid id="bottom-row" container >
                <Grid item xs={2}>{team.matches}</Grid>
                <Grid item xs={1}>{team.wins}</Grid>
                <Grid item xs={1}>{team.draws}</Grid>
                <Grid item xs={2}>{team.losses}</Grid>
                <Grid item xs={2}>{team.goalsScored}</Grid>
                <Grid item xs={2}>{team.goalsAgainst}</Grid>
                <Grid item xs={1}>{team.goalsScored - team.goalsAgainst}</Grid>
              </Grid>
            </Grid>
          ))}

        </CardContent>
        {addTeamAction &&
          <CardActions>
            <Button variant="contained" color="secondary" startIcon={<AddBoxIcon />} onClick={() => addTeamAction(group)}>Add team</Button>
          </CardActions>}
      </React.Fragment>
    </Card>

  );
}

export default Mobile;
