import axios from "axios";
import getEnv from "../../env";

const baseUrl = getEnv().FUTSAL_SERVICE_URL;

export function requestGetGamesGroupStage() {
    return axios.request({
        method: 'get',
        url: baseUrl + '/game/level/GroupStage'
    })
}

export function requestGetGamesQuarterFinal() {
    return axios.request({
        method: 'get',
        url: baseUrl + '/game/level/QuarterFinal'
    })
}

export function requestGetGamesSemiFinal() {
    return axios.request({
        method: 'get',
        url: baseUrl + '/game/level/SemiFinal'
    })
}

export function requestGetGamesFinal() {
    return axios.request({
        method: 'get',
        url: baseUrl + '/game/level/Final'
    })
}

export function requestGetGame({id, token}) {
    return axios.request({
        method: 'get',
        url: baseUrl + '/game/' + id,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}

export function requestMakeGameLive({gameId, token}) {
    return axios.request({
        method: 'put',
        url: baseUrl + '/game/live/' + gameId,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}

export function requestFinishGame({gameId, token}) {
    return axios.request({
        method: 'put',
        url: baseUrl + '/game/finish/' + gameId,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}

export function requestAddGame({game, token}) {
    return axios.request({
        method: 'post',
        url: baseUrl + '/game',
        data: game,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}

export function requestUpdateGame({id, game, token}) {
    return axios.request({
        method: 'put',
        url: baseUrl + '/game/' + id,
        data: game,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}