import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getValidToken } from "../../redux/ducks/user";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getValidToken());
  }, [dispatch]);
  return(
    <div>
      {authenticated === undefined
        ? <div className=''> Loading...</div>
        : authenticated ? children : <Navigate to="/admin/login" replace={true} />
      }
    </div>
   
  )
}
