import { call, put } from "redux-saga/effects";
import { setAuthenticated, setToken, setValidToken } from "../../redux/ducks/user";
import { requestLogin } from "../requests/user";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

export function* handelLogin({ username, password }) {
  try {
    const response = yield call(requestLogin, { username, password });
    const { data: {token}, status: statusCode } = response;
    
    if(statusCode === 401 || statusCode === 400){
      yield put(setToken(undefined));
      yield put(setValidToken(undefined));
      yield put(setAuthenticated(false));
      notifyError("Unauthorized");
    } else {
      yield put(setToken(token));
      yield put(setValidToken(token));
      yield put(setAuthenticated(true));
    }
    
  } catch (err) {
    if(err.response.status === 401){
      notifyError("Unauthorized");
    }
    console.log(err);
    console.log(err.response);
    yield put(setToken(undefined));
    yield put(setValidToken(undefined));
    yield put(setAuthenticated(undefined));
  }
}

export function* handelGetValidToken() {
  const token = localStorage.getItem("token");
  if (!token) {
    yield put(setValidToken(undefined));
    yield put(setAuthenticated(false));
  }
  else {
    try {
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();
      let isTokenExp = decodedToken.exp * 1000 < currentDate.getTime();
      if(isTokenExp)
        {
          yield put(setValidToken(undefined))
          yield put(setAuthenticated(false));
        } else {
          yield put(setValidToken(token));
          yield put(setAuthenticated(true));
        }
    } catch (error) {
      console.log(error);
    }
  }
}

const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });