import axios from "axios";
import getEnv from "../../env";

const baseUrl = getEnv().FUTSAL_SERVICE_URL;

export function requestGetAllGroups(includeHidden) {
    return axios.request({
        method: 'get',
        url: baseUrl + '/group',
        headers: {
            'x-include-hidden': includeHidden 
          }
    })
}

export function requestGetGroup(id) {
    return axios.request({
        method: 'get',
        url: baseUrl + '/group/' + id
    })
}

export function requestAddGroup({group, token}) {
    return axios.request({
        method: 'post',
        url: baseUrl + '/group',
        data: group,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}

export function requestUpdateGroup({id, group, token}) {
    return axios.request({
        method: 'put',
        url: baseUrl + '/group/' + id,
        data: group,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}