import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelIcon from '@mui/icons-material/Cancel';
const useStyles = makeStyles({
    popup: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor:"rgba(0, 0, 0, 0.5)",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },
    popupInner: {
        position: "relative",
        padding: "20px",
        width: "100%",
        maxWidth: "640px",
        // maxHeight: "700px",
        backgroundColor: "#546e7a",
        borderRadius: "20px"
    },
    closeBtn:{
        position: "absolute !important",
        top: "16px",
        right: "16px",
    }
    
  });

function Popup({trigger, children, setTrigger}){
    const styles = useStyles();
    return (trigger)?(
        <div className={styles.popup}>
            <div className={styles.popupInner}>
                <IconButton variant="contained" color="secondary" className={styles.closeBtn} onClick={() => setTrigger(false)}>
                    <CancelIcon fontSize="large"/>
                </IconButton>
                <div style={{paddingTop: "40px"}}>
                {children}
                </div>
                
            </div>
        </div>
    ):""
}

export default Popup;