import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { setActionSuccess, setTeam } from "../../redux/ducks/team";
import {
  requestAddTeam,
  requestGetTeam,
  requestUpdateTeam,
} from "../requests/team";

export function* handelGetTeam({ id , groupId, token}) {
  try {
    if (!id) {
      yield put(
        setTeam({
          name: "",
          groupId: groupId,
          matches: 0,
          wins: 0,
          draws: 0,
          losses: 0,
          goalsScored: 0,
          goalsAgainst: 0,
          points: 0,
        })
      );
    } else {
      const response = yield call(requestGetTeam, {id, token});
      const { data } = response;
      yield put(setTeam(data));
    }
  } catch (err) {
    notifyError(err.response.data.detail);
  }
}

export function* handelAddTeam({ team, token }) {
  try {
    const response = yield call(requestAddTeam, { team, token });
    if (response.status === 201) {
      const { data: team } = response;
      yield put(setTeam(team));
      yield put(setActionSuccess(true));
    } else {
      yield put(setActionSuccess(false));
      console.log(response);
      notifyError(response.data);
    }
  } catch (err) {
    console.log(err.response);
    yield put(setActionSuccess(false));
    notifyError(err.response.data.detail);
  }
}

export function* handelUpdateTeam({ team, token }) {
  try {
    const response = yield call(requestUpdateTeam, {
      id: team.id,
      team,
      token,
    });
    if (response.status === 200) {
      const { data: team } = response;
      yield put(setTeam(team));
      yield put(setActionSuccess(true));
    } else {
      yield put(setActionSuccess(false));
    }
  } catch (err) {
    console.log(err.response);
    yield put(setActionSuccess(false));
    notifyError(err.response.data.detail);
  }
}

const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
