import { useSelector, useDispatch } from "react-redux";
import {  useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  addGroup,
  getGroup,
  setGroup,
  updateGroup,
} from "../../redux/ducks/group";
import { Button, Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import { getEmptyGroup } from "./emptyData";
import Team from "./teamCard";
import { getValidToken } from "../../redux/ducks/user";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "40px",
  },
  team: {
    padding: "15px 10px 10px",
    marginTop: "20px",
    border: "solid white",
    borderRadius: "10px",
  },
  teamHeader: {
    position: "absolute",
    marginTop: "-30px",
    marginLeft: "10px",
    color: "#263238",
    background: "white",
    paddingRight: "5px",
    paddingLeft: "5px",
    borderRadius: "5px",
  },
  saveContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    textAlign: "right",
  },
});


function EditGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const {group, success} = useSelector((state) => state.group);
  const {validToken} = useSelector((state) => state.user);
  
  useEffect(() => {
    dispatch(getGroup(id));
    dispatch(getValidToken());
    if(success) {navigate("/admin")}
    
  }, [dispatch, id, success, navigate]);
  

  const changeHandle = (evt) => {
    const name = evt.target.name;
    if (name.includes(".")) {
      const s = name.split(".");
      const teams = group.teams;
      teams[s[0]][s[1]] = evt.target.value;

      dispatch(
        setGroup({
          ...group,
          teams: teams,
        })
      );
    } else {
      dispatch(setGroup({ ...group, [evt.target.name]: evt.target.value }));
    }
  };

   

  const saveGroup = (event) => {
    event.preventDefault();
    if (group._id) {
      dispatch(updateGroup(group, validToken));
    } else {
      dispatch(addGroup(group, validToken));
    }
  };
  
  return (
    <Box
    sx={{ "& .MuiTextField-root": { m: 1, width: "20ch" } }}
    className={classes.gridContainer}
    noValidate
    autoComplete="off"
  >
    <Typography>Add/Edit group</Typography>
    {group && (
      <div>
        <TextField
          required
          label="Group Name"
          value={group.name}
          name="name"
          onChange={changeHandle}
        />
        <Typography>Teams</Typography>
        {group.teams.map((team, i) => (
          <Team key={i} team={team} index={i} changeHandle={changeHandle} />
        ))}
        <div className={classes.saveContainer}>
           <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={saveGroup}
          >
            Save
          </Button>
        </div>
      </div>
    )}
  </Box>
  );
}
export default EditGroup;
