export const GET_GAMES_GROUP_STAGE = "getGamesGroupStage";
export const GET_GAMES_QUARTER_FINAL = "getGamesQuarterFinal";
export const GET_GAMES_SEMI_FINAL = "getGamesSemiFinal";
export const GET_GAMES_FINAL = "getGamesFinal";
export const GET_GAME = "getGame";
export const ADD_GAME = "addGame";
export const UPDATE_GAME = "updateGame";

const SET_GAMES_GROUP_STAGE = "setGamesGroupStage";
const SET_GAMES_QUARTER_FINAL = "setGamesQuarterFinal";
const SET_GAMES_SEMI_FINAL = "setGamesSimeFinal";
const SET_GAMES_FINAL = "setGamesFinal";
const SET_GAME = "setGame";
const SET_ACTION_SUCCESS = "setActionSuccess";

export const MAKE_GAME_LIVE = "makeGameLive";
export const FINISH_GAME= "finishGame";

export const getGamesGroupStage = () => ({
  type: GET_GAMES_GROUP_STAGE,
});

export const getGamesQuarterFinal = () => ({
  type: GET_GAMES_QUARTER_FINAL,
});

export const getGamesSemiFinal = () => ({
  type: GET_GAMES_SEMI_FINAL,
});

export const getGamesFinal = () => ({
  type: GET_GAMES_FINAL,
});

export const getGame = (id, gameLevel, token) => ({
  type: GET_GAME,
  id: id,
  gameLevel: gameLevel,
  token: token,
});

export const setGamesGroupStage = (gamesGroupStage) => ({
  type: SET_GAMES_GROUP_STAGE,
  gamesGroupStage: gamesGroupStage,
});

export const setGamesQuarterFinal = (gamesQuarterFinal) => ({
  type: SET_GAMES_QUARTER_FINAL,
  gamesQuarterFinal: gamesQuarterFinal,
});

export const setGamesSimeFinal = (gamesSimeFinal) => ({
  type: SET_GAMES_SEMI_FINAL,
  gamesSimeFinal: gamesSimeFinal,
});

export const setGamesFinal = (gamesFinal) => ({
  type: SET_GAMES_FINAL,
  gamesFinal: gamesFinal,
});

export const setGame = (game) => ({
  type: SET_GAME,
  game: game,
});

export const addGame = (game, token) => ({
  type: ADD_GAME,
  game: game,
  token: token,
});

export const updateGame = (game, token) => ({
  type: UPDATE_GAME,
  game: game,
  token: token,
});

export const setActionSuccess = (success) => ({
  type: SET_ACTION_SUCCESS,
  success: success,
});

export const makeGameLive = (game, token) => ({
  type: MAKE_GAME_LIVE,
  game: game,
  token: token,
});

export const finishGame = (game, token) => ({
  type: FINISH_GAME,
  game: game,
  token: token,
});

const initialState = {
  gamesGroupStage: undefined,
  gamesQuarterFinal: undefined,
  gamesSimeFinal: undefined,
  gamesFinal: undefined,
  game: undefined,
  success: false,
};

const actionHandler = (state = initialState, action) => {
  switch (action.type) {
    case SET_GAMES_GROUP_STAGE:
      const { gamesGroupStage } = action;
      return { ...state, gamesGroupStage: gamesGroupStage };
    case SET_GAMES_QUARTER_FINAL:
      const { gamesQuarterFinal } = action;
      return { ...state, gamesQuarterFinal: gamesQuarterFinal };
    case SET_GAMES_SEMI_FINAL:
      const { gamesSimeFinal } = action;
      return { ...state, gamesSimeFinal: gamesSimeFinal };
    case SET_GAMES_FINAL:
      const { gamesFinal } = action;
      return { ...state, gamesFinal: gamesFinal };
    case SET_GAME:
      const { game } = action;
      return { ...state, game: game };
    case SET_ACTION_SUCCESS:
      const { success } = action;
      return { ...state, success: success };
    default:
      return state;
  }
};

export default actionHandler;
