import axios from "axios";
import getEnv from "../../env";

const baseUrl = getEnv().FUTSAL_SERVICE_URL;

export function requestGetTeam({id, token}) {
    return axios.request({
        method: 'get',
        url: baseUrl + '/team/' + id,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}

export function requestAddTeam({team, token}) {
    return axios.request({
        method: 'post',
        url: baseUrl + '/team',
        data: team,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}

export function requestUpdateTeam({id, team, token}) {
    return axios.request({
        method: 'put',
        url: baseUrl + '/team/' + id,
        data: team,
        headers: {
            'Authorization': `Bearer ${token}` 
          }
    })
}