import { call, put } from "redux-saga/effects";
import {
  setActionSuccess,
  setAllGroups,
  setGroup,
} from "../../redux/ducks/group";
import {
  requestGetAllGroups,
  requestAddGroup,
  requestGetGroup,
  requestUpdateGroup,
} from "../requests/group";
import { toast } from "react-toastify";

export function* handelGetAllGroups({includeHidden}) {
  try {
    const response = yield call(requestGetAllGroups, includeHidden);
    if (response.status === 200) {
        const { data } = response;
        yield put(setAllGroups(data));
    } else {
        notifyError(response.data);
    }
    
  } catch (err) {
    notifyError(err.response.data);
  }
}

export function* handelGetGroup({ id }) {
  try {
    if (!id) {
      yield put(setGroup({
        name:"",
      }));
    } else {
      const response = yield call(requestGetGroup, id);
      const { data } = response;
      yield put(setGroup(data));
    }
  } catch (err) {
    notifyError(err.response.data);
  }
}

export function* handelAddGroup({ group, token }) {
  try {
    const response = yield call(requestAddGroup, { group, token });
    if (response.status === 201) {
      const { data: group } = response;
      yield put(setGroup(group));
      yield put(setActionSuccess(true));
    } else {
      yield put(setActionSuccess(false));
      console.log(response)
      notifyError(response.data);
    }
  } catch (err) {
    console.log(err.response)
    yield put(setActionSuccess(false));
    notifyError(err.response.data.detail);
  }
}

export function* handelUpdateGroup({ group, token }) {
  try {
    const response = yield call(requestUpdateGroup, { id: group.id, group, token });
    if (response.status === 200) {
      const { data: group } = response;
      yield put(setGroup(group));
      yield put(setActionSuccess(true));
    } else {
      yield put(setActionSuccess(false));
    }
  } catch (err) {
    console.log(err.response)
    yield put(setActionSuccess(false));
    notifyError(err.response.data.detail);
  }
}


const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
