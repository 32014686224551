import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import groupReducer from "./ducks/group";
import gameReducer from "./ducks/game";
import userReducer from "./ducks/user";
import teamReducer from "./ducks/team";
import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "../sagas/sagaRoot";
const reducer = combineReducers({
  group: groupReducer,
  game: gameReducer,
  user: userReducer,
  team: teamReducer
});
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  {},
  composeEnhancers(applyMiddleware(...middleware))
);
sagaMiddleware.run(watcherSaga);
export default store;
